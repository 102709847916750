import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';
import InfoBox from './InfoBox';
import { fieldItemType, gridSizeType } from '../../../services/GeneralTypes';
import { SeedLotPropType } from '../seedLot/SeedLots';
import UtilsService from '../../../services/UtilsService';

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingBottom: theme.spacing(1, 0),
    maxWidth: '200px',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  infoItem: {
    fontSize: 20,
  },
}));

const ProducerBox = (props) => {
  const {
    fields,
    data: seedLot,
    gridSize,
    subComponents,
    title,
  } = props;

  const { xs, md, lg } = gridSize || {};

  const classes = useStyles();

  const showBox = () => UtilsService.showBoxOfFields(seedLot, fields);

  const getImage = () => {
    const image = get(seedLot, 'producer.image');

    if (!image) {
      return (
        <></>
      );
    }

    return (
      <Grid item xs={12}>
        <img src={image.fileUrl} alt="Imagem Produtor" className={classes.logo} />
      </Grid>
    );
  };

  return (
    <>
      {showBox() && (
        <Grid item lg={lg || 4} md={md || 6} xs={xs || 12}>
          <Grid container className={classes.container} spacing={3}>
            {getImage()}
            <InfoBox
              title={title}
              fields={fields}
              data={seedLot}
              gridSize={{ lg: 12, md: 12, xs: 12 }}
              subComponents={subComponents}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

ProducerBox.propTypes = {
  fields: PropTypes.arrayOf(fieldItemType),
  data: SeedLotPropType,
  gridSize: gridSizeType,
  subComponents: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string,
};

export default ProducerBox;
