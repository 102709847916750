import React, { useState } from 'react';
import { SeedLotPropType } from './SeedLots';
import { fields, buildSeedLot } from './EditSeedLotModal';
import ViewModal from '../../components/ViewModal';

const ViewSeedLotModal = (props) => {
  const {
    seedLot,
  } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Lote de Sementes"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildSeedLot(seedLot)}
      />
    </>
  );
};

ViewSeedLotModal.propTypes = {
  seedLot: SeedLotPropType,
};

export default ViewSeedLotModal;
